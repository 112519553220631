$( document ).ready(function() {
    const config = { attributes: true, childList: true, subtree: true };

    const addCustomClassToToolTip = function(event) {
        const element = $(event.target)
        const target = element.data('bs.tooltip')
        const customClass = $(element).data('customClass')

        if(target.tip && customClass) {
            $(target.tip).addClass(customClass)
        }
    }

    const tooltips = $("[data-toggle='tooltip']")
    tooltips.tooltip()
    tooltips.on('show.bs.tooltip', addCustomClassToToolTip)

    const callback = function(mutationsList, _observer) {
        mutationsList.forEach((mutation) => {
            const newTooltips = $(mutation.target).find("[data-toggle='tooltip']").filter(function() {
                return !$(this).data('bs.tooltip')
            })

            newTooltips.tooltip()
            newTooltips.on('show.bs.tooltip', addCustomClassToToolTip)
        })
    }

    const observer = new MutationObserver(callback);
    observer.observe(document, config);
});

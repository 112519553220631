App.registerThingFormToggler = function () {
    const categoryToggle = function () {
        if (!$('#thing_grid_smart_meter').length || $('.grid-smart-meter-input-col').length) {
            return
        }

        if ($('#thing_grid_smart_meter').prop('checked')) {
            $('.category-input-col').hide()
            $('.category-input-col select').prop('disabled', true);
            $('.grid-smart-meter-input-col').removeClass('col-lg-12')
            $('.grid-smart-meter-input-col').addClass('col-lg-6')
        } else {
            $('.category-input-col').show()
            $('.category-input-col select').prop('disabled', false);
            $('.grid-smart-meter-input-col').removeClass('col-lg-6')
            $('.grid-smart-meter-input-col').addClass('col-lg-12')
        }
    }

    $(document).on('change', '#thing_grid_smart_meter', categoryToggle)

    categoryToggle();
}

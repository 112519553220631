App.idpLoginForm = function () {
  const entraButton = document.getElementById("entra")
  const emailField = document.getElementById("user_email")
  const passwordGroup = document.querySelector(".password")
  const submitButton = document.querySelector("[type=submit]")

  if (entraButton) {
    emailField.addEventListener("input", event => {
      if (event.target.value.match(/.+@neoom.com$/i)) {
        entraButton.classList.remove("d-none")
        passwordGroup.classList.add("d-none")
        submitButton.classList.add("d-none")
      } else {
        entraButton.classList.add("d-none")
        passwordGroup.classList.remove("d-none")
        submitButton.classList.remove("d-none")
      }
    })
  }
}

App.conditionalFormElements = function () {
  document.querySelectorAll("[data-display-if-field]").forEach(field => {
    const targetElement = document.querySelector(field.dataset.displayIfField)
    const targetValue = field.dataset.displayIfValue

    toggleFieldDisplay(field, targetElement, targetValue)

    targetElement.addEventListener("change", event => {
      toggleFieldDisplay(field, event.target, targetValue)
    })
  })
}

function toggleFieldDisplay(field, target, targetValue) {
  if (target.value == targetValue) {
    field.classList.remove("d-none")
    field.querySelectorAll("input, select").forEach(input => {
      input.disabled = false
    })
  } else {
    field.classList.add("d-none")
    field.querySelectorAll("input, select").forEach(input => {
      input.disabled = true
    })
  }
}

App.initActivationTokenInput = function () {
    // Select all input elements with the data-activation-token attribute set to true
    const activationCodeInputs = document.querySelectorAll('input[data-activation-token="true"]');

    activationCodeInputs.forEach(input => {
        input.addEventListener('input', function (event) {
            let value = event.target.value.toUpperCase(); // Convert to uppercase
            value = value.replace(/[^A-Z0-9-]/g, ''); // Allow only alphanumeric and dash
            value = value.split('-').join(''); // Remove existing dashes

            if (value.length > 16) {
                value = value.slice(0, 16); // Limit to 16 characters
            }

            const parts = [];
            for (let i = 0; i < value.length; i += 4) {
                parts.push(value.slice(i, i + 4));
            }

            event.target.value = parts.join('-');
        });
    });
}

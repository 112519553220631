App.selectGatewayEnvironment = function () {
    const enableDisableNamespace = function (initial_setup = false) {
        const environment = $("#environment-select")
        const namespaceType = $("#namespace-type-select")
        const namespace = $("#namespace-input")
        const namespacePrefix = $("#namespace-prefix")
        const namespacePostfix = $("#namespace-postfix")

        switch (environment.val()) {
            case 'staging':
                namespaceType.prop("disabled", false);
                if(namespaceType.val() === 'main') {
                    namespace.prop("disabled", true);
                    namespacePrefix.text('https://')
                    namespace.val('ntuity-staging-api');
                    namespacePostfix.text('.ntuity.dev/rest/iot')
                } else {
                    namespace.prop("disabled", false);
                    namespacePrefix.text('https://dev-')
                    namespacePostfix.text('-staging-api.ntuity.dev/rest/iot')
                    if(!initial_setup) namespace.val('');
                }
                break;
            case 'develop':
                namespaceType.prop("disabled", false);
                if(namespaceType.val() === 'main') {
                    namespace.prop("disabled", true);
                    namespacePrefix.text('https://')
                    namespace.val('ntuity-develop-api');
                    namespacePostfix.text('.ntuity.dev/rest/iot')
                } else {
                    namespace.prop("disabled", false);
                    namespacePrefix.text('https://dev-')
                    namespacePostfix.text('-develop-api.ntuity.dev/rest/iot')
                    if(!initial_setup) namespace.val('');
                }
                break;
            case 'production':
                namespace.prop("disabled", true);
                namespaceType.prop("disabled", true);
                namespaceType.val('main')
                namespacePrefix.text('https://')
                namespace.val('api')
                namespacePostfix.text('.ntuity.io/rest/iot')
                break;
        }
    }

    enableDisableNamespace(true)

    $("#environment-select").change(function () {
        enableDisableNamespace()
    });

    $("#namespace-type-select").change(function () {
        enableDisableNamespace()
    });

    $("#request-configuration-button").click(function () {
        const button = $("#request-configuration-button")
        const icon = $('<i class="fad fa-circle-notch fa-spin mr-2" id="configuration-loading-icon"></i>')
        icon.prependTo(button)
    });
}

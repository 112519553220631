App.initClipboardCopy = function () {
    $(document).on('click', '.clipboard-copy', function () {
        const span = $(this);
        span.addClass('copied')
        span.select();
        navigator.clipboard.writeText(span.text());
        setTimeout(function () {
            span.removeClass('copied')
        }, 800)
    })
}

import location_information from "../locationjson/combined.json"
import helpers from "../globals/helpers";

export default class SiteEditFormValidator {

    supported_countries = ["AT", "DE", "CH", "LI"]

    constructor() {
        this.form = document.getElementsByClassName("edit_site")[0] || document.getElementsByClassName("new_site")[0]
    }

    register() {
        if (!this.form || this.form.getAttribute("observer")) return

        const config = { attributes: true, childList: true, subtree: true }
        const observer = new MutationObserver(this.siteEditFormChanged.bind(this))

        observer.observe(this.form, config);
        this.form.setAttribute("observer", true)
    }

    siteEditFormChanged() {
        this.select_site_country = document.getElementById('site_country')
        this.select_site_state =  document.getElementById('site_state_select')
        this.input_site_state = document.getElementById('site_state_input')
        this.input_site_zip = document.getElementById('site_zip_input')
        this.input_site_city = document.getElementById('site_city')
        this.input_external_integration_enabled = document.getElementById('site_external_integration_enabled')

        if (this.usingSingleAddressField) {
            this.input_site_address = document.getElementById('site_address')
        } else {
            this.input_site_street_name = document.getElementById('site_street_name')
            this.input_site_building_number = document.getElementById('site_building_number')
        }

        if (!this.select_site_country || !this.select_site_state || !this.input_site_state || !this.input_site_zip || !this.input_site_city ) return

        if (!this.select_site_country.getAttribute("listener")) {
            let selected_country = this.select_site_country.options[this.select_site_country.selectedIndex]

            if (selected_country) {
                this.toggleStateAndZipFields(selected_country.value)
                this.setInputFieldNames(selected_country.value)
                this.setStates(selected_country.value)

                if (this.isSupportedCountry(selected_country.value)) {
                    this.select_site_state.value = this.input_site_state.value

                    if (!this.selectHasOption(this.select_site_state, this.input_site_state.value)) {
                        this.updateStateAndZipFields(selected_country)
                        this.setStates(selected_country.value)
                    }
                }
            }

            this.disableEnableDependentFields()
            this.removeIsValidStyling()

            this.select_site_country.addEventListener("change", (event) => {
                selected_country = this.select_site_country.options[this.select_site_country.selectedIndex]

                this.updateStateAndZipFields(selected_country)
                this.setStates(selected_country.value)
            })
            this.select_site_country.setAttribute("listener", true)
        }

        if (this.input_external_integration_enabled && !this.input_external_integration_enabled.getAttribute("listener")) {
            this.input_external_integration_enabled.addEventListener("change", (event) => {
                event.preventDefault()
                event.stopPropagation()
                const checked = event.target.checked
                let externalIntegrationCheckbox = this.input_external_integration_enabled
                let urlContainer = document.getElementById('external-integration-url-input')
                let urlInputField = document.getElementById('site_external_integration_url')
                let irrelevantSettingsContainer = document.getElementById('alarms-prices-configuration-fieldset')

                if (checked) {
                    externalIntegrationCheckbox.checked = true
                    urlContainer.classList.add('show')
                    irrelevantSettingsContainer.classList.remove('show')
                } else {
                    const disable = confirm(I18n.t('vue.external_integration.disable_confirmation'))
                    if (disable) {
                        externalIntegrationCheckbox.checked = false
                        urlContainer.classList.remove('show')
                        irrelevantSettingsContainer.classList.add('show')
                        urlInputField.value = ''
                    } else {
                        externalIntegrationCheckbox.checked = true
                    }
                }
            })
            this.input_external_integration_enabled.setAttribute("listener", true)
        }


        if (!this.select_site_state.getAttribute("listener")) {
            this.select_site_state.addEventListener("change", (event) => {
                let selected_country = this.select_site_country.options[this.select_site_country.selectedIndex]
                let selected_state = this.select_site_state.options[this.select_site_state.selectedIndex]

                this.updateZipFields(selected_country, selected_state)
            })
            this.select_site_state.setAttribute("listener", true)
        }

        if (!this.input_site_zip.getAttribute("listener")) {
            this.input_site_zip.addEventListener("input", (event) => {
                let selected_country = this.select_site_country.options[this.select_site_country.selectedIndex]
                let selected_state = this.select_site_state.options[this.select_site_state.selectedIndex]

                this.removeInputValue(this.input_site_city)

                if (this.usingSingleAddressField) {
                    this.removeInputValue(this.input_site_address)
                } else {
                    this.removeInputValue(this.input_site_street_name)
                    this.removeInputValue(this.input_site_building_number)
                }

                this.disableEnableDependentFields()
                this.removeIsValidStyling()

                this.setCity(selected_country.value, selected_state.value, this.zipWithoutLeadingZeros)
            })
            this.input_site_zip.setAttribute("listener", true)
        }

    }

    selectHasOption(select_field, value) {
        for (let i = 0; i < select_field.options.length; i++) {
            if (select_field.options[i].value === value) {
                return true;
            }
        }
        return false;
    }

    updateZipFields(selected_country, selected_state) {
        this.removeInputValue(this.input_site_city)

        if (this.usingSingleAddressField) {
            this.removeInputValue(this.input_site_address)
        } else {
            this.removeInputValue(this.input_site_street_name)
            this.removeInputValue(this.input_site_building_number)
        }

        this.disableEnableDependentFields()
        this.removeIsValidStyling()
    }

    updateStateAndZipFields(selected_country) {
        this.removeOptions(this.select_site_state)
        this.selectFirstOption(this.select_site_state)

        this.input_site_zip.value = ''
        this.removeInputValue(this.input_site_state)
        this.removeInputValue(this.input_site_city)

        if (this.usingSingleAddressField) {
            this.removeInputValue(this.input_site_address)
        } else {
            this.removeInputValue(this.input_site_street_name)
            this.removeInputValue(this.input_site_building_number)
        }

        this.toggleStateAndZipFields(selected_country.value)
        this.setInputFieldNames(selected_country.value)

        this.disableEnableDependentFields()
        this.removeIsValidStyling()
    }

    setStates(selected_country) {
        if (this.isSupportedCountry(selected_country)) {
            let states = Object.values(location_information[selected_country]).map(state => state.name).sort()
            for (let state of states) {
                this.select_site_state.options[this.select_site_state.options.length] = new Option(state, state)
            }
        }
    }

    setCity(selected_country, selected_state, selected_zip) {
        if (location_information[selected_country] && location_information[selected_country][selected_state]) {
            let zip = location_information[selected_country][selected_state].postcodes.find(element => element[0] == selected_zip)
            this.input_site_city.value = zip ? zip[1] : ""
        }
    }

    isSupportedCountry(country) {
        return this.supported_countries.includes(country)
    }

    removeOptions(element) {
        for (let i = element.options.length - 1; i >= 0; i--) {
            element.options[i] = null
        }
        element.options[0] = new Option(" ")
    }

    removeInputValue(element) {
        element.value = ""
    }

    selectFirstOption(element) {
        element.selectedIndex = 0
    }

    toggleStateAndZipFields(selected_country) {
        let select_fields = document.getElementsByClassName("location-select-input")
        let text_fields = document.getElementsByClassName("location-text-input")

        if (this.isSupportedCountry(selected_country)) {
            for (let field of select_fields) {
                field.classList.remove("d-none")
            }
            for (let field of text_fields) {
                field.classList.add("d-none")
            }
        } else {
            for (let field of select_fields) {
                field.classList.add("d-none")
            }
            for (let field of text_fields) {
                field.classList.remove("d-none")
            }
        }
    }

    setInputFieldNames(selected_country) {
        if (this.isSupportedCountry(selected_country)) {
            this.select_site_state.setAttribute("name", "site[state]")
            this.input_site_state.removeAttribute("name", "site[state]")
        } else {
            this.select_site_state.removeAttribute("name", "site[state]")
            this.input_site_state.setAttribute("name", "site[state]")
        }
    }

    disableOrEnableField(field, disable = true) {
        field.disabled = disable
    }

    disableEnableDependentFields() {
        let country = this.select_site_country.options[this.select_site_country.selectedIndex].value

        if (!country) {
            this.disableOrEnableField(this.select_site_state, true)

            this.disableOrEnableField(this.input_site_state, true)
            this.disableOrEnableField(this.input_site_zip, true)
            this.disableOrEnableField(this.input_site_city, true)

            if (this.usingSingleAddressField) {
                this.disableOrEnableField(this.input_site_address, true)
            } else {
                this.disableOrEnableField(this.input_site_street_name, true)
                this.disableOrEnableField(this.input_site_building_number, true)
            }
        } else {
            this.disableOrEnableField(this.select_site_state, false)

            this.disableOrEnableField(this.input_site_state, false)
            this.disableOrEnableField(this.input_site_zip, false)
            this.disableOrEnableField(this.input_site_city, false)

            if (this.usingSingleAddressField) {
                this.disableOrEnableField(this.input_site_address, false)
            } else {
                this.disableOrEnableField(this.input_site_street_name, false)
                this.disableOrEnableField(this.input_site_building_number, false)
            }
        }

        if (this.isSupportedCountry(country)) {
            let state = this.select_site_state.options[this.select_site_state.selectedIndex]?.value
            let zip = this.input_site_zip.value
            let validZip = zip && location_information[country][state].postcodes.some(postcode => postcode[0] == this.zipWithoutLeadingZeros)

            if (!state) {
                this.disableOrEnableField(this.input_site_zip, true)
            } else {
                this.disableOrEnableField(this.input_site_zip, false)
            }

            if (!zip || !validZip) {
                this.disableOrEnableField(this.input_site_city, true)

                if (this.usingSingleAddressField) {
                    this.disableOrEnableField(this.input_site_address, true)
                } else {
                    this.disableOrEnableField(this.input_site_street_name, true)
                    this.disableOrEnableField(this.input_site_building_number, true)
                }
            } else {
                this.disableOrEnableField(this.input_site_city, false)

                if (this.usingSingleAddressField) {
                    this.disableOrEnableField(this.input_site_address, false)
                } else {
                    this.disableOrEnableField(this.input_site_street_name, false)
                    this.disableOrEnableField(this.input_site_building_number, false)
                }
            }
        }
    }

    removeIsValidStyling() {
        let country = this.select_site_country.options[this.select_site_country.selectedIndex].value

        if (!country) {
            this.select_site_country.classList.remove('is-valid')
        }

        let state
        let zip = this.input_site_zip.value

        if (this.isSupportedCountry(country)) {
            state = this.select_site_state.options[this.select_site_state.selectedIndex]?.value
        } else {
            state = this.input_site_state.value
        }

        if (!state) {
            this.select_site_state.classList.remove('is-valid')
            this.input_site_state.classList.remove('is-valid')
        }

        if (!zip) {
            this.input_site_zip.classList.remove('is-valid')

            if (this.usingSingleAddressField) {
                this.input_site_address.classList.remove('is-valid')
            } else {
                this.input_site_street_name.classList.remove('is-valid')
                this.input_site_building_number.classList.remove('is-valid')
            }
        }
    }

    get usingSingleAddressField() {
        return !!document.getElementById('site_address')
    }

    get zipWithoutLeadingZeros() {
        return parseInt(this.input_site_zip.value, 10).toString()
    }
}

const NTUITY_LINK_VERSIONS_CHART_CONTAINER = 'ntuity-link-versions-chart'
const NTUITY_OS_VERSIONS_CHART_CONTAINER = 'ntuity-os-versions-chart'
import echarts from 'echarts/lib/echarts'
import 'echarts/lib/chart/bar'

App.initCharts = function () {
    if ($(`#${NTUITY_LINK_VERSIONS_CHART_CONTAINER}`).length) { setupVersionsChart(NTUITY_LINK_VERSIONS_CHART_CONTAINER) }
    if ($(`#${NTUITY_OS_VERSIONS_CHART_CONTAINER}`).length) { setupVersionsChart(NTUITY_OS_VERSIONS_CHART_CONTAINER) }
}

const setupVersionsChart = function (containerId) {
    const versions = $(`#${containerId}`).data('versions')
    const labels = versions.map(v => v[0])
    const values = versions.map(v => v[1])

    const myChart = echarts.init(document.getElementById(containerId));

    // specify chart configuration item and data
    const option = {
        tooltip: {},
        xAxis: {
            data: labels
        },
        yAxis: {},
        grid: {
            bottom: 30
        },
        series: [{
            name: 'Versionen',
            type: 'bar',
            data: values,
            label: {
                show: true
            },
            itemStyle: {
                color: '#007DA0'
            }
        }]
    };

    // use configuration item and data specified to show chart
    myChart.setOption(option);
}

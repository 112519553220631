// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("trix")
require("@rails/actiontext")
require("@nathanvda/cocoon")
require("bootstrap")
require("ahoy.js")

import "../application/app"
import("../application/interactions")
import("../application/toggle-date-input")
import("../application/toggle-protocol-input-fields")
import("../application/toggle-site-schema")
import "../application/standard-modal"
import "../application/intro-modal"
import "../application/toggle-thing-form"
import "../application/app-data"
import "../application/toggle-tooltip"
import "../application/selectize-input"
import "../application/tracking"
import "../application/setup-edit-site-form"
import "../application/select_gateway_environment"
import "../application/charts"
import "../application/clipboard-copy"
import "../application/secret"
import "../application/gw-status-indicators"
import "../application/toggle-gateway-activation-form"
import "../application/announce_gw"
import "../application/conditional_form_elements"
import "../application/activation_token"
import "../application/idp_login_form"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

App.showStandardModal = function(title, body) {
    let dialog = $("#standard-modal");
    $(dialog).find(".modal-title").html(title);
    $(dialog).find(".modal-body").html(body);
    $(dialog).modal('show');

    $(dialog).find(".modal-submit").on('click', function () {
        $(dialog).find("form").submit()
    });
}

App.hideStandardModal = function() {
    $("#standard-modal").modal('hide');
}

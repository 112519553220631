// Enable periodic updates of gateway sub-states in admin UI
App.registerGatewayStatusIndicators = function() {
    if($('#gateway-status-indicators').length) {
        const gwId = $('#gateway-status-indicators').data('uuid')
        App.refreshGatewayStatusIndicators(gwId)
    }
}

App.refreshGatewayStatusIndicators = function(gwId) {
    const url = `/admin/gateways/${gwId}/status_indicators`
    $.get(url, null, null, 'script');

    App.refreshGatewayStatusIndicatorsTimer = setTimeout(function (){
        App.refreshGatewayStatusIndicators(gwId)
    }, 1000 * 15)
}
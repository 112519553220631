App.toggleGatewayActivationForm = function () {
    const toggleRetirementWarning = function () {
        const actionDropdown = $('#gateways_activation_associated_gateway_action')
        const retirementWarning = $('#retirement-warning')

        if(actionDropdown.val() === 'retire') {
            retirementWarning.addClass('show')
        } else {
            retirementWarning.removeClass('show')
        }
    }

    $('#gateways_activation_associated_gateway_action').change(function() {
        toggleRetirementWarning()
    })
}

App.showIntroModal = function() {
    if($.find('#intro-video').length > 0) {
        let dialog = $('#intro-video');

        $(dialog).modal('show');
        $(dialog).on('hide.bs.modal', function (e) {
            // send completed request
            $.post($(dialog).data('completeUrl'));
        })
    }
}

$(document).on('ready turbolinks:load', function() {
    App.showIntroModal();
});
